import * as React from "react";
import { Card, Header } from "semantic-ui-react";
import { CdnImage } from "@momenta/common/CdnImage";

const VacanciesCard: React.FC<{}> = () => {
    const vacanciesUrl = "http://www.momentagroup.com/job/";

    return (
        <Card as="a" href={vacanciesUrl} target="_blank" className="dashboard-card">
            <Card.Content textAlign="center">
                <CdnImage src="/vacancies.svg" />
                <Header textAlign="center">Vacancies</Header>
            </Card.Content>
        </Card>
    );
};

export {
    VacanciesCard
};
